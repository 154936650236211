<!--
 * @Author: 月魂
 * @Date: 2021-12-21 13:31:22
 * @LastEditTime: 2022-11-14 17:51:37
 * @LastEditors: 月魂
 * @Description: 藏食物器
 * @FilePath: \food-show\src\views\Utensils.vue
-->
<template>
  <div class="box">
    <div class="cont">
      <Navigation :title="this.$t('utensils.title')" />
      <img src="../assets/temp/utensils.png" alt="" class="img top-bg">
      <img src="../assets/temp/utensils-pc.png" alt="" class="img top-bg pc">
      <div class="intro">
        <div class="des" v-for="(item, index) in this.$t('utensils.des')" :key="index">{{ item }}</div>
      </div>
      <div class="ing-cont">
        <van-tabs v-model="active">
          <van-tab :title="item.name" v-for="(item, index) in this.$t('utensils.list')" :key="index">
            <div class="list">
              <div class="item" v-if="item.video">
                <video controls class="video" :poster="domain + item.video.posterUrl">
                  <source :src="domain + item.video.videoUrl">
                </video>
                <div class="bottom">
                  <div class="title">{{ item.video.name }}</div>
                  <div class="des" v-for="(des, idx) in item.des" :key="idx">{{ des }}</div>
                </div>
              </div>
              <div class="item">
                <div class="bottom">
                  <div class="title">{{ item.imgDes }}</div>
                </div>
                <img v-for="(item, index) in item.imgs" :key="index" :src="domain + item" alt="" class="img pic">
              </div>
            </div>
          </van-tab>
          <!-- <van-tab :title="$t('utensils.ya.name')">
            <div class="list">
              <div class="des" v-for="(des, index) in $t('utensils.ya.des')" :key="index">{{ des }}</div>
              <div class="item">
                <div class="item-title">{{ this.$t('utensils.ya.imgDes') }}</div>
                <img v-for="(item, index) in $t('utensils.ya.imgs')" :key="index" :src="`http://image.wearetogether.com.cn/${item}.jpg`" alt="" class="img">
              </div>
            </div>
          </van-tab> -->
        </van-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Tab, Tabs } from 'vant';
import Navigation from '@/components/Navigation'

Vue.use(Tab).use(Tabs);

export default {
  name: 'Utensils',
  components: {
    Navigation
  },
  data() {
    return {
      active: 0,
      title: '',
      domain: ''
    }
  },
  mounted() {
    this.title = this.$t('utensils.title')
    this.domain = this.$t('domain')
    this.wxShare(this.$t('title'), this.$t('utensils.title'))
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.box {
  min-height: 100vh;
  background: #06062B;
  position: relative;
  padding-bottom: 20px;
  color: #fff;
  .cont {
    max-width: 1200PX;
    margin: auto;
    .top-bg {
      position: absolute;
      top: 0;
      left: 0;
    }
    .pc {
      display: none;
    }
    @media (min-width: 1000PX) {
      .top-bg {
        display: none;
      }
      .pc {
        display: block;
      }
    }
    .intro {
      width: 100%;
      padding: 16px;
      color: #999;
      position: relative;
      z-index: 1;
      @media (min-width: 1000PX) {
        & {
          color: #ccc;
        }
      }
      .des {
        font-size: 12px;
      }
    }
    .ing-cont {
      width: 100%;
      max-width: 1200PX;
      margin: auto;
      padding-top: 24px;
      position: relative;
      z-index: 1;
      .list {
        padding: 16px;
        font-size: 12px;
        .item {
          margin-bottom: 16px;
          border-radius: 15px;
          background: #111135;
          overflow: hidden;
          .img, .video {
            display: block;
            width: 100%;
            border-radius: 15px;
          }
          .pic {
            margin-bottom: 16px;
          }
          .bottom {
            padding: 16px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            line-height: 20px;
            .title {
              font-size: 14px;
              color: #ccc;
            }
            .des {
              color: #999;
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
}
</style>
